@use "./wsbc";
@import "animate.css/animate.min";

.disabled {
  pointer-events: none;
}

// to ensure reCaptcha is not covering WSBC footer
.grecaptcha-badge {
  z-index: 1;
  margin-bottom: 25px;
}

// overwriting incorrect UX lib color:
.blockquote-border-red {
  border-left: 4px solid #dc4405;
}

.disabled-label * {
  color: #c9c5c1;
  pointer-events: none;
}

// this is to compensate for the direct contact being added as a part of footer:
.content-wrapper {
  padding-bottom: 260px;
}

// prevent zoom-in o ninput on mobile devices
@media (max-width: 768px) {
  input.form-control,
  select.form-control,
  textarea.form-control {
    font-size: 16px;
  }
}

.page-template {
  footer {
    height: auto;
  }
}
